import { graphql } from "gatsby";
import React from "react";
import { Card, Container, Modal } from "react-bootstrap";
import VideoLandingPage from "../components/Banner/VideoLandingPage";
import BookAppointment from "../components/BookAppointment/BookAppointment";
import Bookcard from "../components/BookCard/BookCard";
import Events from "../components/Events/Events";
import EventsGarden from "../components/Events/EventsGardens";
import FeaturedEvent from "../components/FeaturedEvent/FeaturedEvent";
import FeaturedProperty from "../components/FeaturedProperty/FeaturedProperty";
import Footer from "../components/Footer/Footer";
import EventsSubscription from "../components/forms/events-subscribe";
import GoogleMap from "../components/GoogleMap/GoogleMap";
import Header from "../components/Header/Header";
import LocalNews from "../components/LocalNews/LocalNews";
import SellWithUs from "../components/SellWithUs/SellWithUs";
import Seo from "../components/seo";

const GardenSquareTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.gardenSquare;
    const globalModule = props.data.glstrapi?.globalModule;
    const [show, setShow] = React.useState(false);

    return (
        <React.Fragment>
            <Seo
                title={GQLPage.Meta_Title} 
                description={GQLPage.Meta_Description}
                location={props.location}
            />
            <Header menuLinks={[]}/>
            <VideoLandingPage
                data={GQLPage}
                imagename="garden-squares.Banner_Image.h_banner"
                modules={[
                    {
                        List_of_Links: GQLPage.Garden_Menu_Links
                    }
                ]}
                breadcrumbs={[
                    {
                        label: "Local living",
                        url: "/local-living/"
                    },
                    {
                        label: "Garden Squares",
                        url: "/local-living/garden-squares/"
                    },
                    {
                        label: GQLPage.Title,
                        url: "/local-living/garden-squares/"+GQLPage.Url
                    },

                ]}
            />
            <div id="intro-garden">
                <FeaturedEvent
                    data={GQLPage.Intro_Garden}
                />
            </div>
            <SellWithUs
                data={GQLPage.About_Garden}
            />
            {GQLPage?.Title &&
                <FeaturedProperty
                    title={`Properties Available for Sale and Rent in ${GQLPage?.Title}`}
                />
            }
            <div className="pt-5 pb-0 gs-map-section">
                <div className="w-100 map-module">
                    <GoogleMap
                        data={[GQLPage]}
                        lat={parseFloat(GQLPage.Latitude)}
                        lng={parseFloat(GQLPage.Longitude)}
                    />
                </div>
            </div>
            <EventsGarden
                path={"/local-living/events/"}
            />
            <Container className="pb-5 garden-bookcard">
                <Bookcard className="pb-5 garden-bookcard"
                    bookcardTitle="Receive notifications of events in Kensington and Chelsea"
                    bookcardContent="From music concerts, cinema nights and dog parties to theatre productions, we have something for everyone"
                    bookcardBtn="register with us"
                    eventPopup={true}
                    openEventForm={() => setShow(true)}
                    column={false}
                    
                />
            </Container>
            <LocalNews/>
            <BookAppointment
                data={globalModule?.Lets_Work_Together}
            />
            <Footer
                popularSearch="Popular_Contact" showBreadcrumb = {true}
            />
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">
                <Card className="border-0">
                    <Card.Img variant="top" src={globalModule?.Events_Subscribe_Form?.Card_Image?.url} />
                    <Card.Header className="px-5 pt-4 border-0 bg-transparent position-relative">
                        <Card.Title>{globalModule?.Events_Subscribe_Form?.Title}</Card.Title>
                        <button onClick={() => setShow(false)} type="button" className="close-modal">
                            <i className="icon-close-modal"></i>
                        </button> 
                    </Card.Header>
                    <Card.Body className="px-5"> 
                        <EventsSubscription 
                            email={globalModule?.Events_Subscribe_Form?.To_Email}
                            bcc={globalModule?.Events_Subscribe_Form?.To_Bcc}
                        />
                    </Card.Body>
                </Card>
            </Modal>
        </React.Fragment>
    )
}

export default GardenSquareTemplate;

export const pageQuery = graphql`
    query gardenQuery($id: ID!) {
        glstrapi {
            gardenSquare(id: $id, publicationState: LIVE) {
                _id
                Title
                Meta_Title
                Meta_Description
                Banner_Image {
                    url
                    alternativeText
                }
                Show_Events
                Show_Local_News
                Propeties_Title
                Publish
                Longitude
                Latitude
                Description
                Video_Url
                Url
                Sort
                Garden_Menu_Links {
                    Anchor_or_External
                    Link_Type
                    Sub_Menu_Label
                    Sub_Menu_Link {
                        _id
                        URL
                    }
                    
                }
                Intro_Garden {
                    _id
                    Title
                    Sm_Title
                    Show_Review
                    Tile_Image {
                        url
                        alternativeText
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(
                                    formats: AUTO
                                    width: 1360
                                    height: 1040
                                    placeholder: BLURRED
                                    quality: 100
                                    transformOptions: {
                                        cropFocus: CENTER, fit: COVER
                                    }
                                )
                            }
                        }
                    }
                    Italic_Text
                    Video_Url
                    Content
                    Cta_Label
                    About_Cta_Link {
                        _id
                        URL
                    }
                    Select_Team {
                        Name
                        Tile_Image {
                            url
                            alternativeText
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData(
                                        formats: AUTO
                                        width: 110
                                        height: 104
                                        placeholder: BLURRED
                                        quality: 50
                                        transformOptions: {
                                            cropFocus: NORTH, fit: COVER
                                        }
                                    )
                                }
                            }
                        }
                        Designation
                        Email
                        Mobile
                        Video_Url
                        Url
                    }
                }
                About_Garden {
                    _id
                    Title
                    Content
                    Sm_Title
                    Anchor_Id
                    Acc_Image: Tile_Image {
                        alternativeText
                        url
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(
                                    formats: AUTO
                                    width: 560
                                    height: 800
                                    placeholder: BLURRED
                                    quality: 50
                                    transformOptions: {
                                        cropFocus: CENTER, fit: COVER
                                    }
                                )
                            }
                        }
                    }
                    Accordian_List {
                        Title
                        Content
                        _id
                    }
                }
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Background_Image {
                        url
                        alternativeText
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(
                                    formats: AUTO
                                    width: 1800
                                    placeholder: BLURRED
                                    quality: 50
                                    transformOptions: {
                                        cropFocus: CENTER, fit: COVER
                                    }
                                )
                            }
                        }
                    }
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                }
                Events_Subscribe_Form {
                    Title
                    Description
                    Card_Image {
                        alternativeText
                        url
                    }
                }
            }
        }
    }
`
