import React,{useState} from "react";
import { Link } from "gatsby";
import "./Offertag.scss";
// import PlayVideo from "../../components/Play/PlayVideo";

const Offertag = (props) => {
  return (
    <span className="open-house">{props.text}</span>
  )
}
export default Offertag;