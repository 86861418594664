import React from "react";
import { Link } from "gatsby";
import Offertag from "../Offertag/Offertag";
import "./PropertyCard.scss";
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image";
import imageConfig from "../../../static/images/config.json";
import PropertyAvailableFrom from "../PropertyCardBig/PropertyAvailableFrom"

const PropertyCard = (props) => {
    const propertyList = props.propertyList;

    return (
        <React.Fragment>
            <div className="property-card img-zoom" id={`propertyList_${props.propertyId}`}>
                <div className="properties-wrapper">
                    <Link
                   
                    to={propertyList.propertyLink} className="overflow-hidden" 
                    onClick={()=>{
                        sessionStorage.setItem('searchindex', props.propertyId)
                    }}
                    >
                        {props.searchResults ?
                            <React.Fragment>
                                {propertyList?.propertyImg?.length > 0 && 
                                    <ShowProcessedImage
                                        images={propertyList.propertyImg[0]}
                                        transforms={
                                            imageConfig.properties.images.grid.sizes
                                        }
                                    /> 
                                }
                            </React.Fragment> :
                            <picture>
                                <img src={propertyList.propertyImg} alt="img" />
                            </picture>
                        }
                    </Link>
                    {propertyList.offerTag && <Offertag text={propertyList.offerTag}/>}
                </div>
                <div className="about-properties">
                    <div className="properties-info">
                        <div className="properties-heading">
                            <h2><Link to={propertyList.propertyLink}> {propertyList.propertyTitle}</Link></h2>
                            <span className="property-price">{propertyList.propertyPrice} {!propertyList.propertyStatus ? "PW": ""} {propertyList?.price_qualifier == "Offers In Excess" ? propertyList.price_qualifier : ""}</span>
                        </div>
                        <p>{propertyList.propertyAddress}</p>
                    </div>
                    <PropertyAvailableFrom available_from={propertyList.available_from} status={propertyList.status} />
                    <div className="properties-icons">
                        {parseInt(propertyList.propertyRoom) > 0 && 
                            <span className="text">
                                <i className="icon-bedroom"></i>
                                <span className="sm-text">{propertyList.propertyRoom}</span>
                            </span>
                        }

                        {parseInt(propertyList.propertyBathroom) > 0 && 
                            <span className="text">
                                <i className="icon-bath"></i>
                                <span className="sm-text">{propertyList.propertyBathroom}</span>
                            </span>
                        }

                        {parseInt(propertyList.propertyReception) > 0 && 
                            <span className="text">
                                <i className="icon-sofa"></i>
                                <span className="sm-text">{propertyList.propertyReception}</span>
                            </span>
                        }

                        {/* <a href={`tel:101010101`} className="text ms-auto me-0 d-md-none">
                            <i className="icon-phone-dark"></i>
                            <span className="sm-text text-link">Call us</span>
                        </a> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyCard;
