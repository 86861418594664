import React,{useEffect, useState} from "react";
import moment from 'moment';

const PropertyAvailableFrom = (props) => {
    let available_from = "";
    var now = moment();
    var available_from_display = "";
    var days_diff = '';
    if(props.available_from) {
        available_from_display = moment(props.available_from).format("DD/MM/YYYY");
        available_from = moment(props.available_from).format("YYYY-MM-DD");
    }
    return (
        <>
        {props.status=="To Let" && props.available_from && available_from!="" && days_diff >= 0 ?
            <p className="available-from">Available from {available_from_display}</p>:''
        }
        </>
    )
}
export default PropertyAvailableFrom;