import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap"
import PropertyCard from "../PropertyCard/PropertyCard"
import Slider from "react-slick";
import "../SimilarProperty/SimilarProperty.scss"
import axios from 'axios';

const FeaturedProperty = (props) => {
    var settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                 settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                       
                    }
            },
            {
                breakpoint: 991,
                 settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false
                       
                    }
            },
            {
                breakpoint: 1500,
                 settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: false
                    }
            },
            {
                breakpoint: 2000,
                 settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: false
                       
                    }
            },
         
        ]
    };

    const [propItems, setPropItems] = useState([]);

    useEffect(() => {
        let url = process.env.GATSBY_STRAPI_SRC + `/stb-lists/item/featured`;
        getItems(url);
    }, []);

    const getItems = async url => {
        try {
            const { data } = await axios.get(url, { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})
            if (data && data.length > 0) {
                setPropItems(data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <div className="similar-property">
            <Container>
                <Row>
                    <Col>
                        <h3>{props?.title ? props?.title : "similar properties in kensington"}</h3>
                        <Slider className="slick-arrow" {...settings}>
                            {propItems.map((property, index) => 
                                <PropertyCard 
                                    key={property.id} 
                                    searchResults={false}
                                    propertyList={{
                                        propertyImg: property.images[0]?.url,
                                        propertyTitle: property.intro,
                                        propertyPrice: `£ ${property.price.toLocaleString()}`,
                                        propertyInfo: property.title,
                                        propertyRoom: property.bedroom,
                                        propertyBathroom: property.bathroom,
                                        propertyReception: property.bathroom,
                                        propertyDesc: property.description,
                                        propertyAddress:property.display_address,
                                        offerTag: property.status?.toLowerCase() === "under offer",
                                        propertyStatus: property.search_type === "sales",
                                        offerText: "Under Offer",
                                        callInfo:'00000000',
                                        propertyLink: `/property-${property.searchType === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property.id}/`
                                    }}
                                />
                            )}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default FeaturedProperty;
