import React, { useState } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { graphql, Link, useStaticQuery } from 'gatsby';
import './Events.scss'
import moment from 'moment';
import axios from 'axios';
import EventbritePopupCheckout from '../EventBrite';
import Slider from 'react-slick';
import ImageTransform from '../common/ggfx-client/module/components/image-transform';

const limit = 9;

const EventsGarden = (props) => {
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                events(where: {Publish: true}, sort:"Date:DESC") {
                    _id
                    Title
                    Description
                    Alias
                    Address
                    Date
                    From
                    EventId
                    Meta_Description
                    Meta_Title
                    Publish
                    Sort
                    To
                    Url
                    Video_Url
                    Tile_Image {
                        alternativeText
                        url
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(
                                    formats: AUTO
                                    width: 1360
                                    height: 1040
                                    placeholder: BLURRED
                                    quality: 100
                                    transformOptions: {
                                        cropFocus: CENTER, fit: COVER
                                    }
                                )
                            }
                        }
                    }
                }
                eventsConnection {
                    aggregate {
                        count
                    }
                }
            }
        }
    `);

    const loadMore = async () => {
        setPage(page+1);
        let url = process.env.GATSBY_STRAPI_SRC + `/events?_start=${page*limit}&_limit=${limit}`;
        try {
            let eventsList = await axios.get(url,  { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})
            setEvents(eventsList.data);
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(async() => {
        setPage(1);
        let string = props?.location?.search?.split("?q=")[1];
        let url = process.env.GATSBY_STRAPI_SRC + `/events?_start=${1}&_limit=${limit}&_where[Title_contains]=${string}`;
        try {
            let eventsList = await axios.get(url,  { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})
            setEvents(eventsList.data);
        } catch (error) {
            console.log(error)
        }
    }, [props?.location?.search]);

    var settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }
        ]
    };

	return (
		<section className="events event-section pb-3">
			<Container>
                <div className='sub-title'>Events in your local garden squares</div>
				<Row>
					<Col>
						<div className="event-slider pt-0 border-0">
                            <Slider className="slick-arrow" {...settings}>
                                {data?.glstrapi?.events?.concat(events)?.map((item, index) => {
                                    let processedImages = JSON.stringify({});
                                    if (item?.imagetransforms?.Tile_Image_Transforms) {
                                        processedImages = item?.imagetransforms?.Tile_Image_Transforms;
                                    }
                                    return (
                                        <div className="event-wrap" key={index}>
                                            <div className="img-wrap img-zoom">
                                                {/* <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} /> */}
                                                <ImageTransform
                                                    imagesources={item?.Tile_Image?.url}
                                                    renderer="pic-src" imagename="events.Tile_Image.banner"
                                                    attr={{ alt: item?.Tile_Image?.alternativeText ? item?.Tile_Image?.alternativeText : item.Title + " - tlc Estate Agents", className: 'm-0' }}
                                                    imagetransformresult={processedImages}
                                                    id={item?._id}
                                                />
                                            </div>
                                            <div className="event-info">
                                                <h2><Link to={props.path+item.Url+"/"}>{item.Title}</Link></h2>
                                                <div className="event-cta">
                                                    <div className="event-date">
                                                        <div className="wrap-icon">
                                                            <i className="icon-calendar"></i>
                                                        </div>
                                                        {moment(item.Date).format("DD/MM/YYYY")}
                                                    </div>
                                                    <div className="book-ticket">
                                                        <EventbritePopupCheckout ebEventId={item.EventId} className={moment().isAfter(item.Date) ? "" : "cursor-pointer"} disabled={moment().isAfter(item.Date) || !item.EventId}>
                                                            <div className="wrap-icon">
                                                                <i className="icon-ticket"></i>
                                                            </div>
                                                            Book Tickets
                                                        </EventbritePopupCheckout>
                                                    </div>
                                                    <div className="view-detail">
                                                        <Link to={props.path+item.Url}>
                                                            <div className="wrap-icon">
                                                                <i className="icon-lines"></i>
                                                            </div>
                                                            <span className="md">Details</span>
                                                            <span className='dd'>View Details</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                )}
                            </Slider>
						</div>
						{/* {(data?.glstrapi?.eventsConnection?.aggregate?.count !== (data?.glstrapi?.events?.length + events.length)) && <div className="btn-wrap">
							<button type='button' className='btn' onClick={loadMore}>load more</button>
						</div>} */}
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default EventsGarden;